
import { Component, Prop, Vue } from "nuxt-property-decorator";
import RouterLink from "~/components/atoms/text/RouterLink.vue";
import PlaceHolderCircles from "~/components/molecules/display/PlaceHolderCircles.vue";
import { BaseLocation, RouteParams } from "~/types/District";

@Component({
  components: {
    RouterLink,
    PlaceHolderCircles,
  },
})
export default class DistrictOtherLocations extends Vue {
  @Prop({ required: false }) isDistrictCity!: boolean;
  @Prop({ required: false, default: false }) isStateSite!: boolean;
  @Prop({ required: true })
  readonly stateDistrictCities!: BaseLocation[];
  @Prop({ required: true })
  readonly otherCountiesInBundesland!: BaseLocation[];
  @Prop({ required: true }) routeParams!: RouteParams;
  @Prop({ required: true })
  readonly moreThanTwentyCounties!: boolean;
  @Prop({ type: Function, required: true }) translateStateName!: (
    name: string,
  ) => string;

  get getTitleText(): string {
    if (this.isDistrictCity) {
      return "Andere kreisfreie Städte";
    }
    if (this.isStateSite) {
      return "Kreisfreie Städte";
    }
    return "Andere Landkreise";
  }

  get getDescriptionText(): string {
    const stateName = this.translateStateName(this.routeParams.category);
    if (this.isDistrictCity) {
      return `Für nähere Informationen zum Heizölpreis in weiteren kreisfreien Städten in ${stateName}, wählen Sie eine Stadt aus der Liste.`;
    }
    if (this.isStateSite) {
      return `Für nähere Informationen zum Heizölpreis in den kreisfreien Städten in ${stateName}, wählen Sie eine Stadt aus der Liste.`;
    }
    return `Für nähere Informationen zum Heizölpreis in weiteren Landkreises in ${stateName}, wählen Sie einen Landkreis aus der Liste.`;
  }

  get showDistricCities(): boolean {
    return this.isDistrictCity || this.isStateSite;
  }
}
