var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('EsySection',{attrs:{"variant":"inside-grid"}},[_c('EsyH2',[_c('strong',[_vm._v(_vm._s(_vm.getTitleText))]),_vm._v(" in\n    "+_vm._s(_vm.translateStateName(_vm.routeParams.category))+"\n  ")]),_vm._v(" "),_c('EsyParagraph',[_vm._v("\n    "+_vm._s(_vm.getDescriptionText)+"\n  ")]),_vm._v(" "),(_vm.isDistrictCity || _vm.isStateSite)?[_c('ul',{staticClass:"counties-list"},_vm._l((_vm.stateDistrictCities),function(city,i){return _c('ListItem',{key:`${i}-${city.name}`},[_c('RouterLink',{attrs:{"href":{
            path: `/heizoelpreise/bundeslaender/${_vm.routeParams.category.toLowerCase()}/stadt-${
              city.link
            }`,
          },"title":`Weiter zur Ansicht zu ${city.name}`}},[_vm._v("\n          "+_vm._s(city.name)+"\n        ")])],1)}),1)]:[_c('ul',{staticClass:"counties-list"},[_vm._l((_vm.otherCountiesInBundesland),function(county,i){return _c('ListItem',{key:`${i}-${county.name}`},[_c('RouterLink',{attrs:{"href":{
            path: `/heizoelpreise/bundeslaender/${_vm.routeParams.category.toLowerCase()}/${county.link.toLowerCase()}`,
          },"title":`Weiter zur Ansicht zu ${county.name}`}},[_vm._v("\n          "+_vm._s(county.name)+"\n        ")])],1)}),_vm._v(" "),(_vm.moreThanTwentyCounties)?_c('PlaceHolderCircles',{attrs:{"marginType":"bottom"}}):_vm._e()],2)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }